import { AppBar, makeStyles, Toolbar } from '@material-ui/core';
import React from 'react';
import { DashboardRoutes } from '../Routes/Dashboard.routes';
import Logo from '../Util/Assets/laco_logo_white.png';
import { AppBarProfile } from './Components/AppBarProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
  },
  appBar: {
    flexGrow: 1,
  },
  toolbar: { ...theme.mixins.toolbar, display: 'flex', justifyContent: 'space-between' },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    overflowY: 'hidden',
  },
  logo: {
    width: 150,
    padding: 10,
  },
}));

export const DashboardPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar color='primary' position='fixed' className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={Logo} alt='Logo' className={classes.logo} />
          <AppBarProfile />
        </Toolbar>
      </AppBar>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <DashboardRoutes />
      </main>
    </div>
  );
};
