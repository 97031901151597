import { Checkbox, CheckboxProps, Grid, Typography } from '@material-ui/core';
import React from 'react';
interface CheckboxWithHelperTextProps extends CheckboxProps {
  label: string;
  helperText?: string;
}

export const CheckboxWithHelperText = (props: CheckboxWithHelperTextProps) => {
  const { label, helperText, ...checkboxProps } = props;
  return (
    <Grid container spacing={2} alignItems='center' wrap='nowrap'>
      <Grid item>
        <Checkbox {...checkboxProps} />
      </Grid>
      <Grid item>
        <Typography variant='body1'>{label}</Typography>
        {helperText && <Typography variant='subtitle2'>{helperText}</Typography>}
      </Grid>
    </Grid>
  );
};
