import { Divider, Grid } from '@material-ui/core';
import React from 'react';
import { CheckboxWithHelperText } from '../CheckboxWithHelperText';
import { EnumFilterConfig } from './api-table-config.interface';
interface EnumFilterInputProps {
  selectedEnums: string[];
  enumConfigs: EnumFilterConfig[];
  onChange: (selected: string[]) => void;
}
export const EnumFilterInput = (props: EnumFilterInputProps) => {
  const { enumConfigs, selectedEnums, onChange } = props;
  return (
    <Grid container>
      {enumConfigs.map((en, index) => (
        <Grid item xs={12} key={index}>
          <CheckboxWithHelperText
            checked={selectedEnums.includes(en.title)}
            color='primary'
            onChange={(_, checked) => {
              onChange(checked ? [...selectedEnums, en.title] : selectedEnums.filter((f) => f !== en.title));
            }}
            label={en.title.charAt(0).toUpperCase() + en.title.substring(1)}
            helperText={en.helperText}
          />
          {index !== enumConfigs.length - 1 && <Divider style={{ margin: '10px 0' }} />}
        </Grid>
      ))}
    </Grid>
  );
};
