export enum ASSEMBLY_ROLES {
  VIEW_ASSEMBLY = 'view-assembly',
  CREATE_ASSEMBLY = 'create-assembly',
  SEARCH_ASSEMBLY = 'search-assembly',
}

export enum Modules {
  ASSEMBLY = 'assembly-service',
}

export const ROLES: { [key in Modules]: string[] } = {
  [Modules.ASSEMBLY]: Object.values(ASSEMBLY_ROLES),
};

export type Roles = ASSEMBLY_ROLES;

export interface User {
  readonly userId: string;
  readonly email: string;
  readonly emailVerified?: boolean;
  readonly fullName?: string;
  readonly picture?: string;
  readonly givenName?: string;
  readonly familyName?: string;
  readonly displayName: string;
  roles?: { [key in Modules]: { roles: string[] } };
}

export interface Metadata {
  documentVersion: number;
  schemaVersion: number;
  createdAt: Date;
  createdBy: User;
  updatedAt: Date;
  updatedBy: User;
}

export interface BadRequestResponse {
  statusCode: number;
  message: string[];
  error: string;
}

export interface NotFoundResponse {
  statusCode: number;
  message: string;
  error: string;
}

export interface SearchElement {
  term?: string;
  enums?: string;
  minDate?: string;
  maxDate?: string;
  minNumber?: number;
  maxNumber?: number;
  fields: string | string[];
}

export interface SearchAction {
  limit?: number;
  page?: number;
  skip?: number;
  sort?: string;
  where?: any;
  search?: SearchElement[];
}

export interface ExportAction {
  where?: any;
  search?: SearchElement[];
}

export interface PaginatedResponse<T> {
  data: T[];
  count: number;
  filtered?: number;
  page?: number;
  pageCount?: number;
  searchParams?: SearchElement[];
  sort?: string;
}
