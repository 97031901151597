import * as Common from '../../../types/common';

export type BackendHealth = {
  details: BackendHealthDetails;
};
export type BackendHealthDetails = {
  versions: BackendHealthVersions;
};
export type BackendHealthVersions = {
  build: string;
  commitTag: string;
  product: string;
  productVersion: string;
};
export class PageLocationStateTables {
  salesOrderDashboardTable?: TableSettings;
  rfqHeaderTable?: TableSettings;
  rfqLinesTable?: TableSettings;
  productsTable?: TableSettings;
  partnersTable?: TableSettings;
  shippingScheduleTable?: TableSettings;
}

export type PageLocationPathStack = {
  previousPagePath?: string[];
};
export type PageLocationState = PageLocationStateTables &
  PageLocationPathStack & {
    version?: string;
    scrollableTabIndex?: number;
    lightTheme?: boolean;
    validationDataExp?: number | null;
  };
export type TableSettings = {
  query?: Common.SearchAction;
  rowSelected?: number;
};
export interface MainAppContextValues {
  // error?: AxiosError<Common.BadRequestResponse | Common.NotFoundResponse>;
  getPageLocationState: <Property extends keyof PageLocationState>(property: Property) => PageLocationState[Property];
  appendToPageLocationState: (state: PageLocationState) => void;
  appendToPageLocationStateTable: (tableKey: keyof PageLocationStateTables, state: TableSettings) => void;
  clearPageLocationTableSettings: (tableKey?: keyof PageLocationStateTables) => void;
  addToPagePathStack: (path: string) => void;
  popFromPagePathStack: () => string | undefined;
  clearPagePathStack: () => void;
  // resolvePromise: (promise: Promise<any>, successMessage?: string, suppressError?: boolean) => Promise<any>;
  // resolvePromiseWithCancelToken: (
  //   url: string,
  //   query?: Common.SearchAction,
  //   cancelPreviousRequest?: boolean,
  // ) => Promise<any>;
  // dashboardDrawerOpen: boolean;
  // toggleDashboardDrawer: () => void;
  // openDashboardDrawer: () => void;
  // closeDashboardDrawer: () => void;
  // axios: React.MutableRefObject<AxiosInstance>;
  // axiosTemplateService: React.MutableRefObject<AxiosInstance>;
  // getBackendHealth: () => Promise<BackendHealth | undefined>;
  lightTheme: boolean;
  toggleLightTheme: () => void;
}
