import {
  Checkbox,
  Grid,
  GridProps,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearRounded';
import SearchIcon from '@material-ui/icons/Search';
import { isArray } from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Common from '../../../types/common';
import { reduceObject } from '../../Util/Helpers';
import { PageLocationStateTables, useMainAppProvider } from '../../Util/Providers';
import { ApiTableColumn, ApiTableConfig, tableDataTypeEnum } from './api-table-config.interface';
import { TableSettingsDrawer } from './TableSettingsDrawer';

interface ReloadTableOptions {
  query?: Common.SearchAction;
  clearTable?: boolean;
  clearSelected?: boolean;
  resetPageNumber?: boolean;
  setLoading?: boolean;
}
interface TableActions {
  reloadTable: (options?: ReloadTableOptions) => void;
}
interface TableMetaData<T> {
  tableData: T[];
  tableDataLoading: boolean;
  selectedRows: (string | number)[];
  search: Common.SearchElement[] | undefined;
}
type CustomColumnRenders<T> = TableMetaData<T> &
  TableActions & {
    row: T;
    setRow: (row: T) => void;
    rowIndex: number;
  };
export interface ApiTableProps<TableDataType> {
  config: ApiTableConfig<TableDataType>;
  getData: (
    query: Common.SearchAction,
  ) => Promise<TableDataType[] | Common.PaginatedResponse<TableDataType> | null | undefined>;
  initQuery?: Common.SearchAction;
  customColumnRender?: { [index: string]: (data: CustomColumnRenders<TableDataType>) => React.ReactElement };
  pageLocationStateTableKey?: keyof PageLocationStateTables;
  rowsPerPageOptions?: number[] | null;
  loading?: boolean;
  disabled?: boolean | 'while-loading';
  showChildrenWhenLoading?: boolean;
  childGridWrapperProps?: GridProps;
  dense?: boolean;
  hideMainSearch?: boolean;
  hideHeader?: boolean;
  selectableKey?: string;
  disableAllSelection?: boolean;
  selectedRows?: (string | number)[];
  selectAllValuesOnLoad?: boolean;
  rowDisabled?: (row: TableDataType, meta: TableMetaData<TableDataType>) => boolean;
  onRowOfTableClicked?: (row: TableDataType, rowIndex: number, meta: TableMetaData<TableDataType>) => void;
  onSelectionChange?: (selectedRows: (string | number)[], row: TableDataType | 'all', newCheckedValue: boolean) => void;
  children?:
    | React.ReactElement
    | ((data: TableActions & TableMetaData<TableDataType>) => React.ReactElement | null)
    | null;
}
interface StringIndexSignature {
  [index: string]: any;
}
export const ApiTable = <TableDataType extends StringIndexSignature>(props: ApiTableProps<TableDataType>) => {
  const {
    config,
    getData,
    initQuery,
    customColumnRender,
    pageLocationStateTableKey,
    showChildrenWhenLoading,
    childGridWrapperProps,
    dense,
    hideMainSearch,
    hideHeader,
    selectableKey,
    disableAllSelection,
    selectAllValuesOnLoad,
    rowDisabled,
    onRowOfTableClicked,
    onSelectionChange,
    children,
  } = props;
  const { getPageLocationState, appendToPageLocationStateTable } = useMainAppProvider();
  const { enqueueSnackbar } = useSnackbar();

  const rowsPerPageOptions =
    props.rowsPerPageOptions === null ? undefined : props.rowsPerPageOptions ? props.rowsPerPageOptions : [10, 25, 50];

  const [tableData, setTableData] = React.useState<TableDataType[]>([]);
  const [tableDataLoading, setTableDataLoading] = React.useState(false);
  const [totalRecords, setTotalRecords] = React.useState(0);

  const [columnOrder, setColumnOrder] = React.useState<ApiTableColumn<TableDataType>[]>(config.columns);
  const [hiddenColumns, setHiddenColumns] = React.useState<string[]>(config.hiddenColumns || []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOptions ? rowsPerPageOptions[0] : undefined);

  const [search, setSearch] = React.useState<Common.SearchElement[]>();
  const [sort, setSort] = React.useState<string>();

  const [selectedRows, setSelectedRows] = React.useState<(string | number)[]>([]);

  const setTableFiltersFromSearchAction = (query: Common.SearchAction) => {
    setSort(query?.sort);
    setSearch(query?.search);
    setPage(query?.page ? query.page - 1 : 0);
    setRowsPerPage(query?.limit);
    pageLocationStateTableKey && appendToPageLocationStateTable(pageLocationStateTableKey, { query });
  };

  const reloadTable = (options?: ReloadTableOptions): Promise<TableDataType[] | undefined> => {
    options?.setLoading !== false && setTableDataLoading(true);
    options?.clearTable && setTableData([]);
    options?.clearSelected && setSelectedRows([]);
    const query = {
      limit: rowsPerPage,
      page: options?.resetPageNumber ? 1 : page + 1,
      sort,
      search,
      ...options?.query,
      where: { ...config.condition, ...options?.query?.where },
    };
    setTableFiltersFromSearchAction(query);
    return getData(query)
      .then((resp) => {
        console.log('ApiTable', { query, resp });
        resp !== null && setTableDataLoading(false);
        if (isArray(resp)) {
          setTableData(resp);
          setTotalRecords(resp.length);
          return resp;
        } else {
          setTableData(resp?.data || []);
          setTotalRecords(resp?.filtered || 0);
          return resp?.data;
        }
      })
      .catch((err) => {
        err.message && enqueueSnackbar(err.message, { variant: 'error' });
        setTableDataLoading(false);
        return undefined;
      });
  };

  const sortedColumnsObj = React.useMemo(
    () =>
      (sort
        ?.split(' ')
        .reduce((acc, curr) => ({ ...acc, [curr.replace('-', '')]: curr.includes('-') ? 'desc' : 'asc' }), {}) ||
        {}) as {
        [index: string]: 'desc' | 'asc' | undefined;
      },
    [sort],
  );

  const handleSortArrowClick = (property: string, shiftKey: boolean) => {
    let newSort = sort;
    const direction = sortedColumnsObj[property];
    if (shiftKey) {
      if (!direction) newSort += ` ${property}`;
      else if (direction === 'asc') newSort = newSort?.replace(property, `-${property}`);
      else if (direction === 'desc') newSort = newSort?.replace(`-${property}`, '');
    } else {
      if (!direction) newSort = property;
      else if (direction === 'asc') newSort = '-' + property;
      else if (direction === 'desc') newSort = undefined;
    }
    reloadTable({ query: { sort: newSort?.trim() } });
  };

  const tableMetaData: TableMetaData<TableDataType> = React.useMemo(
    () => ({
      tableData,
      tableDataLoading,
      selectedRows,
      search,
    }),
    [tableData, tableDataLoading],
  );

  const allSelected = React.useMemo(
    () =>
      selectableKey
        ? tableData.every((row) =>
            rowDisabled && rowDisabled(row, tableMetaData)
              ? true
              : selectedRows?.includes(reduceObject(row, selectableKey)),
          )
        : false,
    [selectedRows, tableData],
  );

  const tableActions: TableActions = {
    reloadTable,
  };

  const visibleColumns = React.useMemo(
    () => columnOrder.filter((column) => !hiddenColumns.includes(column.title)),
    [hiddenColumns, columnOrder],
  );

  const loading = React.useMemo(() => props.loading || tableDataLoading, [props.loading, tableDataLoading]);

  const allDisabled = React.useMemo(
    () => (props.disabled === 'while-loading' ? loading : props.disabled),
    [props.disabled, loading],
  );

  const handleRowSelected = (row: TableDataType | 'all', checked: boolean, overrideTableData?: TableDataType[]) => {
    if (selectableKey && !allDisabled) {
      let newSelectedRows: (string | number)[] = [];
      if (row !== 'all') {
        const value = reduceObject(row, selectableKey);
        newSelectedRows = checked ? selectedRows.filter((f) => f !== value) : [...selectedRows, value];
      } else if (row === 'all') {
        const allValues = (overrideTableData || tableData)
          .map((row) =>
            (rowDisabled ? !rowDisabled(row, tableMetaData) : true) ? reduceObject(row, selectableKey) : null,
          )
          .filter((f) => f);
        if (checked) {
          newSelectedRows = selectedRows.filter((f) => !allValues.includes(f));
        } else {
          newSelectedRows = [...new Set([...selectedRows, ...allValues])];
        }
      }
      setSelectedRows(newSelectedRows);
      onSelectionChange && onSelectionChange(newSelectedRows, row, !checked);
    }
  };

  const handleRowClickedOrSelected = (row: TableDataType | 'all', index: number, checked: boolean) => {
    if (!allDisabled) {
      if (row !== 'all') {
        selectableKey
          ? handleRowSelected(row, checked)
          : onRowOfTableClicked && onRowOfTableClicked(row, index, tableMetaData);
      } else if (row === 'all') {
        handleRowSelected(row, checked);
      }
    }
  };

  const mainSearchFields = React.useMemo(
    () =>
      config.columns
        .map((column) =>
          column.dataType === tableDataTypeEnum.STRING ||
          (column.dataType === tableDataTypeEnum.COMPONENT && column.property)
            ? column.property
            : null,
        )
        .filter((f) => f) as string[],
    [config],
  );

  React.useEffect(() => {
    const storedQuery = pageLocationStateTableKey ? getPageLocationState(pageLocationStateTableKey)?.query : undefined;
    reloadTable({
      query: { ...storedQuery, ...initQuery, where: { ...storedQuery?.where, ...initQuery?.where } },
    }).then((resp) => resp && selectAllValuesOnLoad && handleRowSelected('all', false, resp));
  }, []);

  React.useEffect(() => {
    props.selectedRows && setSelectedRows(props.selectedRows);
  }, [props.selectedRows]);

  return (
    <Paper>
      {!hideHeader && (
        <Toolbar>
          <Grid container spacing={5} alignItems='stretch' justifyContent={!loading ? 'space-between' : undefined}>
            <Grid item style={{ display: 'flex', columnGap: 8, alignItems: 'center' }}>
              {(loading ? showChildrenWhenLoading : true) && (
                <Grid item {...childGridWrapperProps}>
                  {typeof children === 'function' ? children({ ...tableActions, ...tableMetaData }) : children}
                </Grid>
              )}
              {selectableKey && selectedRows.length !== 0 && (
                <Grid item>{`${selectedRows.length} item${selectedRows.length > 1 ? 's' : ''} selected`}</Grid>
              )}
            </Grid>
            {loading && (
              <Grid item sm={12} md container alignItems='flex-end'>
                <Grid item xs>
                  <LinearProgress />
                </Grid>
              </Grid>
            )}
            {!hideMainSearch && (
              <Grid item sm={12} md={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
                <TextField
                  fullWidth
                  id='main-table-search'
                  label='Search'
                  value={search?.find((f) => isArray(f.fields))?.term || ''}
                  onChange={(event) =>
                    setSearch([
                      ...(search ? search.filter((f) => !isArray(f.fields)) : []),
                      { term: event.target.value, fields: mainSearchFields },
                    ])
                  }
                  onKeyPress={(event) => event.key === 'Enter' && document.getElementById('main-table-search')?.blur()}
                  onBlur={() => reloadTable()}
                  InputProps={{
                    endAdornment: (
                      <>
                        <IconButton size='small' onClick={() => reloadTable()}>
                          <SearchIcon />
                        </IconButton>
                        <IconButton
                          size='small'
                          onClick={() => reloadTable({ query: { search: search?.filter((s) => !isArray(s.fields)) } })}
                        >
                          <ClearIcon />
                        </IconButton>
                      </>
                    ),
                  }}
                />
                <TableSettingsDrawer
                  orderedConfigColumns={columnOrder}
                  setOrderedConfigColumns={setColumnOrder}
                  searchElement={search || []}
                  setSearchElement={setSearch}
                  hiddenColumns={hiddenColumns}
                  setHiddenColumns={setHiddenColumns}
                  onClose={reloadTable}
                />
              </Grid>
            )}
          </Grid>
        </Toolbar>
      )}
      <TableContainer>
        <Table stickyHeader aria-label='sticky table' size={dense ? 'small' : undefined}>
          <TableHead>
            <TableRow>
              {visibleColumns.map((configColumn, index) => (
                <TableCell key={index}>
                  {index === 0 && selectableKey && (
                    <Checkbox
                      size='small'
                      checked={allSelected}
                      disabled={disableAllSelection}
                      onChange={() => !disableAllSelection && handleRowSelected('all', allSelected)}
                    />
                  )}
                  {configColumn.disableSort ? (
                    configColumn.title
                  ) : (
                    <TableSortLabel
                      active={!!sortedColumnsObj[configColumn.property]}
                      direction={sortedColumnsObj[configColumn.property]}
                      onClick={(event) => handleSortArrowClick(configColumn.property, event.shiftKey)}
                    >
                      {configColumn.title}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row, rowIndex) => {
              const rowSelected = selectableKey ? selectedRows.includes(reduceObject(row, selectableKey)) : false;
              const disabled = rowDisabled ? rowDisabled(row, tableMetaData) : false;
              return (
                <TableRow
                  hover
                  key={rowIndex}
                  onClick={() => !disabled && handleRowClickedOrSelected(row, rowIndex, rowSelected)}
                >
                  {visibleColumns.map((configColumn, configColumnIndex) => (
                    <TableCell key={`${rowIndex}-${configColumnIndex}`}>
                      {configColumnIndex === 0 && selectableKey && (
                        <Checkbox
                          size='small'
                          checked={rowSelected}
                          disabled={disabled}
                          onChange={(event) => {
                            event.stopPropagation();
                            !disabled && handleRowSelected(row, rowSelected);
                          }}
                        />
                      )}
                      {configColumn.customRenderKey &&
                      customColumnRender &&
                      customColumnRender[configColumn.customRenderKey]
                        ? customColumnRender[configColumn.customRenderKey]({
                            ...tableMetaData,
                            ...tableActions,
                            row,
                            setRow: (newRow) => {
                              const newTableRows = [...tableData];
                              newTableRows[rowIndex] = newRow;
                              setTableData(newTableRows);
                            },
                            rowIndex,
                          })
                        : configColumn.render
                        ? configColumn.render(row, rowIndex)
                        : reduceObject(row, configColumn.property || '')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsPerPageOptions && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalRecords}
          rowsPerPage={rowsPerPage || -1}
          page={page}
          component='div'
          onPageChange={(_, newPage) => reloadTable({ query: { page: newPage + 1 } })}
          onRowsPerPageChange={(event) => reloadTable({ query: { limit: parseInt(event.target.value) } })}
        />
      )}
    </Paper>
  );
};
