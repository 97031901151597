import { Grid, TextField } from '@material-ui/core';
import React from 'react';
type MinMaxNumber = { minNumber: string; maxNumber: string };
interface NumberFilterInputProps extends MinMaxNumber {
  onChange: (value: MinMaxNumber) => void;
}
export const NumberFilterInput = (props: NumberFilterInputProps) => {
  const { minNumber, maxNumber, onChange } = props;
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <TextField
          autoFocus
          fullWidth
          id='min-filter-field'
          label='Min Number'
          type='number'
          value={minNumber}
          onChange={(event) => onChange({ minNumber: event.target.value, maxNumber })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          id='max-filter-field'
          label='Max Number'
          type='number'
          value={maxNumber}
          onChange={(event) => onChange({ minNumber, maxNumber: event.target.value })}
        />
      </Grid>
    </Grid>
  );
};
