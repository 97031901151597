import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DashboardPage } from '../Common/Layout/DashboardPage';
import { LoginPage } from '../Common/Layout/LoginPage';
import { PrivateRoute } from '../Common/Routes/PrivateRoute';
import { Modules } from '../types';

export function Routes() {
  return (
    <Switch>
      <Redirect exact from='/' to='/dashboard' />
      <Route exact path='/login' component={LoginPage} />
      <PrivateRoute path='/dashboard' component={DashboardPage} accessModule={Modules.ASSEMBLY} />
    </Switch>
  );
}
