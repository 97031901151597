import { createTheme as createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { MainAppContextValues } from '.';
import { AppLocale, Language } from '../Lang';
import { PageLocationState, PageLocationStateTables, TableSettings } from './main-app-interface';

const MainAppContext = React.createContext({} as MainAppContextValues);

const createTheme = (lightTheme: boolean) =>
  createMuiTheme({
    palette: {
      type: lightTheme ? 'light' : 'dark',
      primary: {
        main: lightTheme ? '#ff2424' : '#cf0000', //#ff6163
        light: '#ff8c8c',
      },
      secondary: {
        main: '#ff9920',
      },
    },
    typography: {
      caption: {
        fontSize: 15,
        color: 'gray',
      },
    },
    spacing: 2,
  });

const locale = Language.EN;
const intlProps = {
  messages: AppLocale[locale],
  locale: locale.toString(),
};
export const useMainAppProvider = () => React.useContext(MainAppContext);
export const MainAppProvider = (props: { children?: React.ReactNode | undefined }) => {
  // const { keycloak, initialized } = useKeycloak();
  // const { enqueueSnackbar } = useSnackbar();
  // const kcToken = keycloak?.token ?? '';

  const pageLocationStateKey = 'appData';
  // const axiosCancelRequestMessage = 'Request canceled';

  // const [dashboardDrawerOpen, setDashboardDrawerOpen] = React.useState(true);
  // const [error, setError] = React.useState<AxiosError<Common.BadRequestResponse | Common.NotFoundResponse>>();

  // const [cancelTokenSource, setCancelTokenSource] = React.useState<{ [index: string]: CancelTokenSource }>({});

  // const openDashboardDrawer = () => setDashboardDrawerOpen(true);
  // const closeDashboardDrawer = () => setDashboardDrawerOpen(false);
  // const toggleDashboardDrawer = () => setDashboardDrawerOpen(!dashboardDrawerOpen);

  // React.useEffect(() => {
  //   if (error?.response) {
  //     const messages = error.response.data.message;
  //     typeof messages === 'string'
  //       ? enqueueSnackbar(messages, { variant: 'error' })
  //       : messages?.forEach((m) => enqueueSnackbar(m, { variant: 'error' }));
  //   } else if (error) {
  //     enqueueSnackbar('Request timed out', { variant: 'error' });
  //   }
  // }, [error]);

  // const createSuccessMessage = (mes: string) => enqueueSnackbar(mes, { variant: 'success' });

  // const resolvePromise = (promise: Promise<any>, successMessage?: string, suppressError?: boolean): Promise<any> => {
  //   return promise
  //     .then((resp) => {
  //       if (!resp?.data?.error) {
  //         successMessage && createSuccessMessage(successMessage);
  //       } else if (JSON.stringify(resp.data.error) !== '{}') {
  //         setError({
  //           response: { data: { message: 'Unable to preform action' } },
  //         } as any);
  //       }
  //       return resp.data;
  //     })
  //     .catch((err) => {
  //       console.log({ err });
  //       if (err?.message === axiosCancelRequestMessage) {
  //         return null;
  //       } else {
  //         !suppressError && setError(err);
  //         return undefined;
  //       }
  //     });
  // };

  // const resolvePromiseWithCancelToken = (
  //   url: string,
  //   query?: Common.SearchAction,
  //   cancelPreviousRequest = false,
  // ): Promise<any> => {
  //   const cancelToken = axios.CancelToken.source();
  //   if (cancelPreviousRequest) {
  //     cancelTokenSource[url]?.cancel(axiosCancelRequestMessage);
  //     setCancelTokenSource({ ...cancelTokenSource, [url]: cancelToken });
  //   }
  //   return resolvePromise(axiosApi.current.post(url, query, { cancelToken: cancelToken.token }));
  // };

  // const createAxiosApiInstance = () =>
  //   axios.create({
  //     baseURL: config.apiUri,
  //     headers: {
  //       Authorization: initialized ? `Bearer ${kcToken}` : undefined,
  //     },
  //   });

  // const createAxiosTemplateServiceInstance = () =>
  //   axios.create({
  //     baseURL: config.templateServiceUri,
  //     headers: {
  //       Authorization: initialized ? `Bearer ${kcToken}` : undefined,
  //     },
  //   });

  // const axiosApi = React.useRef<AxiosInstance>(createAxiosApiInstance());

  // const axiosTemplateService = React.useRef<AxiosInstance>(createAxiosTemplateServiceInstance());

  const setPageLocationState = (state: PageLocationState) => {
    localStorage.setItem(pageLocationStateKey, JSON.stringify({ ...state }));
  };

  const appendToPageLocationState = (state: PageLocationState) => {
    setPageLocationState({ ...getPageLocationState(), ...state });
  };

  const getPageLocationState = (property?: keyof PageLocationState) => {
    const data = localStorage.getItem(pageLocationStateKey);
    return data ? (property ? JSON.parse(data)[property] : JSON.parse(data)) : undefined;
  };

  const getInitLightThemeState = () => {
    const pageLocationLightThemeState = getPageLocationState('lightTheme');
    return pageLocationLightThemeState === undefined ? true : pageLocationLightThemeState;
  };

  const [lightThemeState, setLightThemeState] = React.useState<boolean>(getInitLightThemeState());

  const toggleLightTheme = () => {
    const newLightThemeState = !lightThemeState;
    setLightThemeState(newLightThemeState);
    appendToPageLocationState({ lightTheme: newLightThemeState });
  };

  const appendToPageLocationStateTable = (tableKey: keyof PageLocationStateTables, state: TableSettings) => {
    appendToPageLocationState({ [tableKey]: { ...getPageLocationState(tableKey), ...state } });
  };

  const clearPageLocationTableSettings = (tableKey?: keyof PageLocationStateTables) => {
    const currentPageState = getPageLocationState();
    setPageLocationState(
      tableKey
        ? { ...currentPageState, [tableKey]: undefined }
        : Object.keys(new PageLocationStateTables()).reduce((acc, tableKey) => {
            delete acc[tableKey];
            return acc;
          }, currentPageState),
    );
  };

  const addToPagePathStack = (path: string) => {
    const paths: string[] = getPageLocationState('previousPagePath') || [];
    paths.push(path);
    appendToPageLocationState({ previousPagePath: paths });
  };
  const popFromPagePathStack = () => {
    const paths: string[] = getPageLocationState('previousPagePath') || [];
    const path = paths.pop();
    appendToPageLocationState({ previousPagePath: paths });
    return path;
  };

  const clearPagePathStack = () => {
    appendToPageLocationState({ previousPagePath: [] });
  };

  // const getBackendHealth = (): Promise<BackendHealth | undefined> =>
  //   resolvePromise(axiosApi.current.get('health').catch((e) => (e.response.status === 503 ? e.response : e)));

  // React.useEffect(() => {
  //   console.log('token changed', { resourceAccess: keycloak.resourceAccess });
  //   axiosApi.current = createAxiosApiInstance();
  // }, [config.apiUri, initialized, kcToken]);

  // React.useEffect(() => {
  //   axiosTemplateService.current = createAxiosTemplateServiceInstance();
  // }, [config.templateServiceUri, initialized, kcToken]);

  React.useEffect(() => {
    const version = window.env.REACT_APP_PRODUCT_VERSION || 'local';
    if (getPageLocationState('version') !== version) {
      setPageLocationState({ version });
    }
  }, []);

  const theme = React.useMemo(() => createTheme(lightThemeState || lightThemeState === undefined), [lightThemeState]);

  return (
    <MainAppContext.Provider
      value={{
        // error,
        getPageLocationState,
        appendToPageLocationState,
        appendToPageLocationStateTable,
        clearPageLocationTableSettings,
        addToPagePathStack,
        popFromPagePathStack,
        clearPagePathStack,
        // resolvePromise,
        // resolvePromiseWithCancelToken,
        // dashboardDrawerOpen,
        // openDashboardDrawer,
        // closeDashboardDrawer,
        // toggleDashboardDrawer,
        // axios: axiosApi,
        // axiosTemplateService,
        // getBackendHealth,
        lightTheme: lightThemeState || lightThemeState === undefined,
        toggleLightTheme,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <IntlProvider {...intlProps}>{props.children}</IntlProvider>
      </MuiThemeProvider>
    </MainAppContext.Provider>
  );
};
