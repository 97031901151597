import React from 'react';
import { assemblyLegend, AssemblyTested } from '../../types';

interface Props {
  tested: AssemblyTested;
}
export const AssemblyTestedAbbr = ({ tested }: Props) => {
  return (
    <>
      <abbr title={assemblyLegend(tested)}>{tested}</abbr>
      &nbsp;
    </>
  );
};

export const testedArray = (tested: AssemblyTested[]) => (
  <>
    {tested.map((t) => (
      <AssemblyTestedAbbr tested={t} key={t} />
    ))}
  </>
);
