import { RestError } from './RestError';

interface RequestData<B = {}, Q = {}, P = {}> {
  body?: B;
  queryParams?: Q;
  pathParams?: P;
  headers?: Record<string, string>;
}

export type HTTPMethod = 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';

export interface RestSpec<R extends RequestData> {
  url: string;
  method?: HTTPMethod;
  body?: R['body'];
  headers?: Record<string, string>;
  pathParams?: R['pathParams'];
  queryParams?: R['queryParams'];
  publishEvent?: boolean;
  cacheTime?: number;
  responseType?: string;
}

export interface RestResponse<T> {
  ok: boolean;
  status: number;
  headers: { [key: string]: string };
  response: T;
}

export default async function doFetch<RES, REQ = {}>(restSpec: RestSpec<REQ>): Promise<RestResponse<RES>> {
  const requestInit = {
    method: restSpec.method,
    headers: withCommonHeaders(restSpec.method ?? 'GET', restSpec.headers),
    body: restSpec.body ? JSON.stringify(restSpec.body) : null,
  };

  return fetch(
    clearEdgeSlashes(makeUrlPath(withPathParams(restSpec.url, restSpec.pathParams))) +
      withQueryParams(restSpec.queryParams),
    requestInit,
  )
    .then(
      (resp) =>
        resp.text().then((text) => ({
          ok: resp.ok,
          status: resp.status,
          response: parseJson(text),
          rawResponse: text,
          headers: extractHeaders(resp.headers),
        })),
      () => {
        throw new RestError(500, 'Connection error');
      },
    )
    .then((resp) => {
      if (!resp.ok) {
        throw new RestError(resp.status, resp.rawResponse, resp);
      }
      return resp;
    });
}

export function extractResponse<T>(restResponse: RestResponse<T>): T {
  return restResponse.response;
}

function extractHeaders(headers: Headers): { [key: string]: string } {
  const extracted: { [key: string]: string } = {};
  headers.forEach((value, key) => {
    extracted[key.toLowerCase()] = value;
  });

  return extracted;
}

function withQueryParams(params?: any) {
  const query =
    params &&
    Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== null && params[key] !== '')
      .reduce<string[]>((acc, key) => {
        if (params[key] && typeof params[key] === 'object' && (params[key] as string[]).length !== undefined) {
          (params[key] as string[]).forEach((val) => {
            acc.push(`${key}=${encodeURIComponent(val)}`);
          });
        } else {
          acc.push(`${key}=${encodeURIComponent(params[key])}`);
        }
        return acc;
      }, [])
      .join('&');
  return query ? `?${query}` : '';
}

function parseJson(text: string) {
  try {
    return JSON.parse(text);
  } catch (e) {
    return {};
  }
}

function withCommonHeaders(method: HTTPMethod, requestHeaders?: Record<string, string>) {
  const headers = requestHeaders ? { ...requestHeaders } : {};
  headers.Accept = headers.Accept || 'application/json';
  if (method !== 'GET') {
    headers['Content-Type'] = headers['Content-Type'] || 'application/json;charset=utf-8';
  }

  return headers;
}

function withPathParams(url: string, pathParams: any = {}) {
  let urlCopy = String(url);
  const rest = Object.keys(pathParams).filter((key) => {
    const pathParam = `{${key}}`;
    const willReplace = urlCopy.indexOf(pathParam) !== -1;
    urlCopy = urlCopy.replace(pathParam, String(pathParams[key]));
    return !willReplace;
  });

  urlCopy = makeUrlPath(
    urlCopy,
    ...rest
      .filter((key) => pathParams[key] !== '' && pathParams[key] !== undefined)
      .map((key) => `/${key}/${pathParams[key]}`),
  );

  return urlCopy;
}

function makeUrlPath(...args: string[]) {
  return args.map(clearEdgeSlashes).join('/');
}

function clearEdgeSlashes(value: string) {
  let str = value[0] === '/' ? value.slice(1) : value;
  str = str[str.length - 1] === '/' ? str.slice(0, -1) : str;
  return str;
}
