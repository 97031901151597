import { Card, CardContent, CardHeader, Divider, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import React from 'react';
import * as Yup from 'yup';
import { CheckboxWithHelperText } from '../../Common/Components/CheckboxWithHelperText';
import FormikTextField from '../../Common/Components/Formik/FormikTextField';
import { useAssemblyService, useSerialService } from '../../services';
import { Assembly, assemblyLegend, AssemblyTested } from '../../types';
import { RestError } from '../../utils/RestError';
import { AssemblyMetaCard } from './AssemblyMetaCard';

export interface FormAssembly extends Omit<Assembly, '_id' | 'id' | 'metadata'> {
  isLoaded: boolean;
}

export const AddAssemblyForm = (props: { onAssemblyCreated?: () => void }) => {
  const { enqueueSnackbar } = useSnackbar();

  const serialService = useSerialService();
  const assemblyService = useAssemblyService();

  const defaultInitialValues: FormAssembly = {
    harnessId: '',
    serialNumber: '',
    drawingRev: '',
    bomRev: '',
    wireListRev: '',
    tested: [],
    isLoaded: false,
    job: '',
    suffix: '',
    part: '',
    description: '',
  };

  const testedEnums = Object.values(AssemblyTested);

  return (
    <Formik
      initialValues={defaultInitialValues}
      validationSchema={Yup.object({
        isLoaded: Yup.boolean(),
        serialNumber: Yup.string().required('Serial number is required'),
        harnessId: Yup.string().when('isLoaded', { is: true, then: Yup.string().required('Harness ID is required') }),
        drawingRev: Yup.string().when('isLoaded', {
          is: true,
          then: Yup.string().required('Drawing revision is required'),
        }),
        bomRev: Yup.string().when('isLoaded', { is: true, then: Yup.string().required('BOM revision is required') }),
        wireListRev: Yup.string().when('isLoaded', {
          is: true,
          then: Yup.string().required('Wire list revision is required'),
        }),
        tested: Yup.array().when('isLoaded', { is: true, then: Yup.array().min(1, 'Required') }),
      })}
      onReset={() => (document.getElementById('serial-number') as HTMLInputElement)?.focus()}
      onSubmit={(values, { setValues, resetForm }) => {
        const { isLoaded, ...dto } = values;
        (document.getElementById('serial-number') as HTMLInputElement)?.blur();
        if (isLoaded) {
          assemblyService
            .create(dto)
            .then(() => {
              resetForm();
              enqueueSnackbar('Creating assembly', { variant: 'success' });
              props.onAssemblyCreated && props.onAssemblyCreated();
            })
            .catch((e: RestError) => {
              console.error(e);
              enqueueSnackbar(`Error creating assembly: ${e.message}`, { variant: 'error' });
              resetForm();
            });
        } else {
          serialService
            .getSerialNumber({ serial: values.serialNumber })
            .then((res) => res.response)
            .then((serial) => {
              setValues({ ...serial, tested: [], isLoaded: true });
              (document.getElementById('harness-id') as HTMLInputElement)?.focus();
              (document.getElementById('harness-id') as HTMLInputElement)?.select();
            })
            .catch(() => {
              enqueueSnackbar('Unable to load serial', { variant: 'error' });
              setValues(defaultInitialValues);
            });
        }
      }}
    >
      {({ values, errors, resetForm, setValues }) => {
        // eslint-disable-next-line react/prop-types
        const isLoaded = values.isLoaded;
        return (
          <Form id='add-assembly-form'>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <FormikTextField
                  fullWidth
                  id='serial-number'
                  name='serialNumber'
                  label='Serial number'
                  type='text'
                  placeholder='scan serial number'
                  autoFocus
                  onClick={() => resetForm()}
                />
              </Grid>
              {isLoaded && (
                <>
                  <Grid item xs={12}>
                    <AssemblyMetaCard assembly={values} />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      id='harness-id'
                      name='harnessId'
                      label='Harness ID'
                      type='text'
                      placeholder='Harness ID'
                      preventSubmitOnEnter
                      disabled={!isLoaded}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      id='drawing-rev'
                      name='drawingRev'
                      label='Drawing revision'
                      type='text'
                      placeholder='drawing revision'
                      preventSubmitOnEnter
                      disabled={!isLoaded}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      id='bom-rev'
                      name='bomRev'
                      label='BOM revision'
                      type='text'
                      placeholder='bom revision'
                      preventSubmitOnEnter
                      disabled={!isLoaded}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextField
                      fullWidth
                      id='wire-list-rev'
                      name='wireListRev'
                      label='Wire list revision'
                      type='text'
                      placeholder='wire list revision'
                      preventSubmitOnEnter
                      disabled={!isLoaded}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title='Testing'
                        subheader={errors.tested}
                        subheaderTypographyProps={{ color: 'error' }}
                        style={{ marginBottom: -25 }}
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          {testedEnums.map((assemblyTestedEnum, index) => (
                            <Grid item xs={12} key={assemblyTestedEnum}>
                              <CheckboxWithHelperText
                                disabled={
                                  assemblyTestedEnum !== AssemblyTested.NOT_TESTED &&
                                  values.tested.includes(AssemblyTested.NOT_TESTED)
                                }
                                checked={values.tested.includes(assemblyTestedEnum)}
                                color='primary'
                                onChange={(_, checked) => {
                                  setValues({
                                    ...values,
                                    tested:
                                      assemblyTestedEnum === AssemblyTested.NOT_TESTED
                                        ? checked
                                          ? [assemblyTestedEnum]
                                          : []
                                        : checked
                                        ? [...values.tested, assemblyTestedEnum]
                                        : values.tested.filter((f) => f !== assemblyTestedEnum),
                                  });
                                }}
                                label={
                                  assemblyTestedEnum.charAt(0).toLocaleUpperCase() + assemblyTestedEnum.substring(1)
                                }
                                helperText={assemblyLegend(assemblyTestedEnum)}
                              />
                              {index !== testedEnums.length - 1 && <Divider style={{ margin: '3px 0' }} />}
                            </Grid>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
