import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

export interface SimpleDialogProps extends Omit<DialogProps, 'onClose' | 'title'> {
  title: string | React.ReactNode;
  content: string | React.ReactNode;
  loading?: boolean;
  closeButtonText?: string;
  handleClose: () => void;
  acceptButtonText?: string;
  acceptDisabled?: boolean;
  handleAccepted?: () => void;
  additionalAction?: React.ReactElement;
}
export const SimpleDialog = (props: SimpleDialogProps) => {
  const {
    title,
    content,
    loading,
    closeButtonText,
    handleClose,
    acceptButtonText,
    acceptDisabled,
    handleAccepted,
    additionalAction,
    ...dialogProps
  } = props;
  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      onClick={(event) => event.stopPropagation()}
      aria-labelledby='simple-dialog-dialog'
      {...dialogProps}
    >
      <DialogTitle id='yes-no-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {typeof content === 'string'
          ? content.split('\n').map((contentPice, index) => (
              <Typography align='center' variant='body1' key={index}>
                {contentPice}
              </Typography>
            ))
          : content}
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress size={35} />
        ) : (
          <>
            <Button color='secondary' variant='contained' onClick={handleClose}>
              {closeButtonText || 'No'}
            </Button>
            {handleAccepted && (
              <Button color='primary' variant='contained' disabled={acceptDisabled} onClick={handleAccepted}>
                {acceptButtonText || 'Yes'}
              </Button>
            )}
            {additionalAction}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
