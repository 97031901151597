import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { Loading } from './Common/Components/Loading';
import { MainAppProvider } from './Common/Util/Providers';
import { Routes } from './routes';

export default function App() {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  return (
    <MainAppProvider>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </MainAppProvider>
  );
}
