import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { AssembliesTable } from '../Components/Tables';

const AssembliesPage = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography color='inherit' variant='h4'>
          Assemblies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <AssembliesTable />
      </Grid>
    </Grid>
  );
};

export default AssembliesPage;
