import { Card, CardContent, Typography } from '@material-ui/core';
import React from 'react';
import { Assembly } from '../../types';

interface AssemblyMetaAccordionProps {
  assembly: Partial<Assembly>;
}
export const AssemblyMetaCard = (props: AssemblyMetaAccordionProps) => {
  const { assembly } = props;
  return (
    <Card>
      <CardContent style={{ paddingBottom: 16 }}>
        <Typography variant='subtitle2'>{`${assembly.part} (${assembly.job}-${assembly.suffix})`}</Typography>
        <Typography variant='subtitle2'>{assembly.description}</Typography>
      </CardContent>
    </Card>
  );
};
