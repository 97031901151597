import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AuthClientError, AuthClientEvent } from '@react-keycloak/core';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'whatwg-fetch';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const keycloak = Keycloak('/keycloak.json');

const eventLogger = (eventType: AuthClientEvent, error?: AuthClientError) => {
  console.debug('onKeycloakEvent', eventType, error);
};

const tokenLogger = (tokens: unknown) => {
  console.debug('onKeycloakTokens', tokens);
};

ReactDOM.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    // initOptions={{ onLoad: 'login-required' }}
    onEvent={eventLogger}
    onTokens={tokenLogger}
  >
    <CssBaseline />
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <SnackbarProvider maxSnack={5} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <BrowserRouter>
          <Suspense fallback={null}>
            <App />
          </Suspense>
        </BrowserRouter>
      </SnackbarProvider>
    </MuiPickersUtilsProvider>
  </ReactKeycloakProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
