import axios from 'axios';
import { Assembly, AssemblySearchResponse, CreateAssemblyDto, ExportAction, SearchAction } from '../types';
import fetch, { RestSpec } from '../utils/fetch';
import { useService } from './useService';

const basePath = window.env.ASSEMBLY_SERVICE_URL || process.env.REACT_APP_ASSEMBLY_SERVICE_URL || '';
export class AssemblyService {
  private readonly token: string;

  constructor(token: string) {
    this.token = token;
  }

  async getAssemblies(search: SearchAction, newToken?: string) {
    return fetch<AssemblySearchResponse>(
      this.withAuthHeaders({ method: 'POST', url: `${basePath}/search`, body: search }, newToken),
    );
  }

  async create(assembly: CreateAssemblyDto, newToken?: string) {
    return fetch<Assembly>(
      this.withAuthHeaders(
        {
          method: 'POST',
          url: basePath,
          body: assembly,
        },
        newToken,
      ),
    );
  }

  getAssembly(assemblyId: string, newToken?: string) {
    return fetch<Assembly>(
      this.withAuthHeaders(
        {
          method: 'GET',
          url: `${basePath}/{assemblyId}`,
          pathParams: {
            assemblyId,
          },
        },
        newToken,
      ),
    );
  }

  downloadAssemblies(search: ExportAction | undefined, newToken?: string) {
    return axios.post(`${basePath}/export`, search, {
      responseType: 'blob',
      headers: { Authorization: `Bearer ${newToken || this.token}` },
    });
  }

  private withAuthHeaders<T>(spec: RestSpec<T>, newToken?: string) {
    return {
      ...spec,
      headers: {
        ...spec.headers,
        Authorization: `Bearer ${newToken || this.token}`,
      },
    };
  }
}

export function useAssemblyService() {
  return useService(AssemblyService);
}
