import { Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';
type MinMaxDate = { minDate: Date | null; maxDate: Date | null };
interface DateFilterInputProps extends MinMaxDate {
  onChange: (value: MinMaxDate) => void;
}
export const DateFilterInput = (props: DateFilterInputProps) => {
  const { minDate, maxDate, onChange } = props;

  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <KeyboardDatePicker
          autoFocus
          autoOk
          disableToolbar
          variant='inline'
          format='MM/dd/yyyy'
          margin='none'
          id='min-filter-date-picker'
          label='Min Date'
          maxDate={maxDate}
          value={minDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
          onChange={(minDate) => onChange({ minDate, maxDate })}
        />
      </Grid>
      <Grid item xs={6}>
        <KeyboardDatePicker
          autoOk
          disableToolbar
          variant='inline'
          format='MM/dd/yyyy'
          margin='none'
          id='max-filter-date-picker'
          label='Max Date'
          minDate={minDate}
          value={maxDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          fullWidth
          onChange={(maxDate) => onChange({ minDate, maxDate })}
        />
      </Grid>
    </Grid>
  );
};
