export class RestError {
  private _status: number;
  private _message: string;
  private _response: any;
  constructor(status: number, message: string, response?: any) {
    this._status = status;
    this._message = message;
    this._response = response;
  }

  public get status(): number {
    return this._status;
  }

  public get message(): string {
    try {
      const data = JSON.parse(this._message);
      return data.message;
    } catch {
      return this._message;
    }
  }

  public get response(): any {
    return this._response;
  }
}
