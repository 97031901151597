import { ApiTableConfig, tableDataTypeEnum } from '../../../Common/Components/ApiTable/api-table-config.interface';
import { Assembly, assemblyLegend, AssemblyTested } from '../../../types';
import { testedArray } from '../AssemblyTestedAbbr';

export const allAssembliesTableConfig: ApiTableConfig<Assembly> = {
  title: 'All',
  columns: [
    { title: 'Harness ID', property: 'harnessId', dataType: tableDataTypeEnum.STRING },
    { title: 'Serial', property: 'serialNumber', dataType: tableDataTypeEnum.STRING },
    { title: 'DR rev', property: 'drawingRev', dataType: tableDataTypeEnum.STRING },
    { title: 'BOM rev', property: 'bomRev', dataType: tableDataTypeEnum.STRING },
    { title: 'WL rev', property: 'wireListRev', dataType: tableDataTypeEnum.STRING },
    {
      title: 'Tested',
      property: 'tested',
      dataType: tableDataTypeEnum.ENUM,
      enums: Object.values(AssemblyTested).map((val) => ({ title: val, helperText: assemblyLegend(val) })),
      render: (row) => testedArray(row.tested),
    },
    { title: 'LACO PN', property: 'part', dataType: tableDataTypeEnum.STRING },
    {
      title: 'Job',
      property: 'job',
      dataType: tableDataTypeEnum.STRING,
      render: (row) => `${row.job}-${row.suffix}`,
    },
    { title: 'Description', property: 'description', dataType: tableDataTypeEnum.STRING },
    {
      title: 'Date Entered',
      property: 'metadata.createdAt',
      render: (row) => new Date(row.metadata.createdAt).toLocaleString(),
      dataType: tableDataTypeEnum.DATE,
    },
  ],
  hiddenColumns: ['LACO PN', 'Job', 'Description', 'Date Entered'],
};
