import { Button, Grid, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudDownload from '@material-ui/icons/CloudDownload';
import RefreshIcon from '@material-ui/icons/Refresh';
import React from 'react';
import { ApiTable } from '../../../Common/Components/ApiTable/ApiTable';
import { SimpleDialog } from '../../../Common/Components/Dialogs/SimpleDialog';
import { useUserAccessControl } from '../../../Common/Routes/private-routes.service';
import { useAssemblyService } from '../../../services';
import * as Common from '../../../types/common';
import { AddAssemblyForm } from '../AddAssemblyForm';
import { allAssembliesTableConfig } from './';

export const AssembliesTable = () => {
  const { getAssemblies, downloadAssemblies } = useAssemblyService();
  const { userHasAccess } = useUserAccessControl();

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const userCanAddAssembly = React.useMemo(
    () => userHasAccess(Common.Modules.ASSEMBLY, [Common.ASSEMBLY_ROLES.CREATE_ASSEMBLY]),
    [],
  );

  const handleDownloadCSV = async (search?: Common.SearchAction) => {
    const resp = await downloadAssemblies(search);
    const blobURL = URL.createObjectURL(resp.data);
    const tempLink = document.createElement('a');
    const date = new Date();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = `${date.getHours()}`.padStart(2, '0');
    const min = `${date.getMinutes()}`.padStart(2, '0');
    const sec = `${date.getSeconds()}`.padStart(2, '0');

    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', `export-${date.getFullYear()}-${month}-${day}-T${hours}${min}${sec}.csv`);

    if (tempLink.download === undefined) {
      tempLink.setAttribute('target', '_blank');
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  return (
    <>
      <ApiTable
        config={allAssembliesTableConfig}
        getData={(query) => getAssemblies(query).then((resp) => resp.response)}
        showChildrenWhenLoading
        childGridWrapperProps={{ container: true, spacing: 3, alignItems: 'center' }}
      >
        {({ reloadTable, search }) => (
          <>
            <Grid item>
              <Button
                color='primary'
                variant='contained'
                disabled={!userCanAddAssembly}
                onClick={() => setDialogOpen(true)}
              >
                <AddIcon />
              </Button>
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleDownloadCSV({ search })}>
                <CloudDownload />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton onClick={() => reloadTable()}>
                <RefreshIcon />
              </IconButton>
            </Grid>
            {dialogOpen && (
              <SimpleDialog
                maxWidth='sm'
                fullWidth
                title='Add Assembly'
                content={<AddAssemblyForm onAssemblyCreated={reloadTable} />}
                open
                closeButtonText='cancel'
                handleClose={() => {
                  setDialogOpen(false);
                  reloadTable();
                }}
                additionalAction={
                  <>
                    <Button variant='contained' color='secondary' type='reset' form='add-assembly-form'>
                      Clear
                    </Button>
                    <Button variant='contained' color='primary' type='submit' form='add-assembly-form'>
                      Create
                    </Button>
                  </>
                }
              />
            )}
          </>
        )}
      </ApiTable>
    </>
  );
};
