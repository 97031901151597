import { Avatar, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useMainAppProvider } from '../../Util/Providers';

type KeycloakParsedToken = { picture?: string; name?: string; email?: string };
export const AppBarProfile = () => {
  const { keycloak } = useKeycloak();
  const { lightTheme, toggleLightTheme } = useMainAppProvider();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleSignOut = () => keycloak.logout();

  const parsedToken = React.useMemo(() => keycloak.idTokenParsed as KeycloakParsedToken, [keycloak.idTokenParsed]);

  return (
    <div style={{ direction: 'rtl', flex: 1 }}>
      <IconButton
        aria-label='account of current user'
        aria-controls='menu-appbar'
        aria-haspopup='true'
        onClick={handleMenu}
        color='inherit'
      >
        <Avatar src={parsedToken.picture} />
      </IconButton>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={anchorEl !== undefined}
        onClose={handleClose}
      >
        {parsedToken.name && <MenuItem onClick={handleClose}>{parsedToken.name}</MenuItem>}
        {parsedToken.email && <MenuItem onClick={handleClose}>{parsedToken.email}</MenuItem>}
        {(parsedToken.name || parsedToken.email) && <Divider />}
        <MenuItem
          onClick={() => {
            toggleLightTheme();
            handleClose();
          }}
        >{`${lightTheme ? 'Dark' : 'Light'} Mode`}</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
        {keycloak && keycloak.token && <MenuItem onClick={() => copy(keycloak.token || '')}>Copy Token</MenuItem>}
      </Menu>
    </div>
  );
};
