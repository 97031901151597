import { Metadata, PaginatedResponse } from './common';

export enum AssemblyTested {
  NOT_TESTED = 'NO TEST',
  HAND = 'HAND',
  CTS = 'CTS',
  HVT = 'HVT',
  BERT = 'BERT',
  TORQUE = 'TORQUE'
}

export const assemblyLegend = (t: AssemblyTested): string => {
  switch (t) {
    case AssemblyTested.NOT_TESTED:
      return 'Not tested';
    case AssemblyTested.HAND:
      return 'Hand test';
    case AssemblyTested.CTS:
      return 'A continuity test, insulation test, and Hi-Pot test is performed for harnesses under 300 volts.';
    case AssemblyTested.HVT:
      return 'Only a Hi-Pot test (for harnesses above 300 volts) is performed.';
    case AssemblyTested.BERT:
      return 'Performed a Bit Error Rate Test on compatible harnesses';
    case AssemblyTested.TORQUE:
      return 'Harness has been torqued based on the manufacturer\'s specification requirements';
  }
};

export interface Assembly {
  metadata: Metadata;
  harnessId: string;
  serialNumber: string;
  drawingRev: string;
  bomRev: string;
  wireListRev: string;
  job: string;
  suffix: string;
  part: string;
  description: string;
  tested: AssemblyTested[];
}

export interface AssemblySearchResponse extends PaginatedResponse<Assembly> {
  data: Assembly[];
}

export class CreateAssemblyDto {
  readonly harnessId!: string;
  readonly serialNumber!: string;
  readonly drawingRev!: string;
  readonly bomRev!: string;
  readonly wireListRev!: string;
  readonly tested!: AssemblyTested[];
}
