import { useKeycloak } from '@react-keycloak/web';
import { useMemo } from 'react';

export function useService<T extends object>(Service: new (token: string) => T) {
  const keycloak = useKeycloak();

  return useMemo(() => {
    const handler = {
      get(target: typeof Service, propKey: keyof typeof Service) {
        const origMethod: Function = target[propKey];
        return function (...args: any) {
          if (!keycloak.keycloak.token) {
            return Promise.reject(new Error('Missing token. Please login first.'));
          }
          args.push(keycloak.keycloak.token);
          return origMethod.apply(target, args);
        };
      },
    };
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new Proxy(new Service(keycloak.keycloak.token!), handler as T);
  }, [keycloak.keycloak.token, Service]);
}
