import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { FieldHookConfig, useField } from 'formik';
import * as React from 'react';

type TextFieldProps = {
  onUpdate?: (fieldName: string, value: string) => void;
  preventSubmitOnEnter?: boolean;
} & FieldHookConfig<string> &
  MuiTextFieldProps;

const FormikTextField = (props: TextFieldProps) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const { children, onUpdate, preventSubmitOnEnter, onBlur: propsOnBlur, ...rest } = props;
  const [field, meta] = useField(rest);
  const showError = meta.touched && !!meta.error;

  const onBlur = (e: React.FocusEvent<any>) => {
    field.onBlur && field.onBlur(e);
    propsOnBlur && propsOnBlur(e);
    onUpdate && onUpdate(props.name, meta.value.toString());
  };

  return (
    <>
      <MuiTextField
        {...field}
        {...rest}
        error={showError}
        helperText={showError ? meta.error : props.helperText}
        onKeyPress={
          rest.onKeyPress ||
          ((event) =>
            preventSubmitOnEnter && event.key === 'Enter'
              ? event.preventDefault()
              : rest.onKeyPress && rest.onKeyPress(event))
        }
        onBlur={onBlur}
      >
        {children}
      </MuiTextField>
    </>
  );
};
export default FormikTextField;
