import React from 'react';
import * as Common from '../../../types/common';
import { formatBackendDateStringToDate, formatDateToBackendDateString } from '../../Util/Helpers';
import { Modify } from '../../Util/Interfaces/general-types';
import { EnumFilterConfig, tableDataTypeEnum } from './api-table-config.interface';
import { DateFilterInput } from './DateFilterInput';
import { EnumFilterInput } from './EnumFilterInput';
import { NumberFilterInput } from './NumberFilterInput';
import { StringFilterInput } from './StringFilterInput';
interface FilterInputsProps {
  filterType: tableDataTypeEnum;
  enums?: EnumFilterConfig[];
  value: Modify<Omit<Common.SearchElement, 'fields'>, { enums?: string[] }>;
  onChange: (value: Modify<Omit<Common.SearchElement, 'fields'>, { enums?: string[] }>) => void;
}
export const FilterInputs = (props: FilterInputsProps) => {
  const { filterType, enums, value, onChange } = props;
  switch (filterType) {
    case tableDataTypeEnum.STRING:
      return <StringFilterInput value={value.term || ''} onChange={(term) => onChange({ term })} />;
    case tableDataTypeEnum.DATE:
      return (
        <DateFilterInput
          minDate={formatBackendDateStringToDate(value.minDate)}
          maxDate={formatBackendDateStringToDate(value.maxDate)}
          onChange={(dates) =>
            onChange({
              minDate: formatDateToBackendDateString(dates.minDate) || undefined,
              maxDate: formatDateToBackendDateString(dates.maxDate) || undefined,
            })
          }
        />
      );
    case tableDataTypeEnum.NUMBER:
      return (
        <NumberFilterInput
          minNumber={value.minNumber ? value.minNumber + '' : ''}
          maxNumber={value.maxNumber ? value.maxNumber + '' : ''}
          onChange={(numbers) =>
            onChange({ minNumber: parseInt(numbers.minNumber), maxNumber: parseInt(numbers.maxNumber) })
          }
        />
      );
    case tableDataTypeEnum.ENUM:
      return (
        <EnumFilterInput
          enumConfigs={enums || []}
          selectedEnums={value.enums || []}
          onChange={(en) => onChange({ enums: en })}
        />
      );
    default:
      return <></>;
  }
};
