import { Button, Card, CardActions, CardContent, CardHeader, Grid, makeStyles, useTheme } from '@material-ui/core';
import { useKeycloak } from '@react-keycloak/web';
import React from 'react';
import { useLocation } from 'react-router-dom';
import LogoSloganBlack from '../Util/Assets/laco_logo_slogan.png';
import LogoWhite from '../Util/Assets/laco_logo_white.png';

const useStyles = makeStyles({
  root: {
    height: 100 + 'vh',
    backgroundImage: 'linear-gradient(to top right, #ff8f8f, #ff2424)',
    alignItems: 'center',
  },
  media: {
    height: 100,
  },
});

export const LoginPage = () => {
  const { keycloak } = useKeycloak();
  const location = useLocation<{ from?: string }>();
  const classes = useStyles();
  const referrer = `${window.location.href.slice(0, -6)}${location?.state?.from || '/'}`;

  const logout = () => keycloak?.logout();

  const login = (options?: Keycloak.KeycloakLoginOptions) => keycloak?.login(options);

  const isDarkTheme = useTheme().palette.type === 'dark';

  React.useEffect(() => {
    //force logout on load
    if (keycloak.authenticated) logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item>
            <Card raised>
              <CardHeader title='Siemens Portal' />
              <CardContent>
                <img className={classes.media} src={isDarkTheme ? LogoWhite : LogoSloganBlack} />
              </CardContent>

              <CardActions disableSpacing>
                <Button color='primary' variant='contained' onClick={() => login({ redirectUri: referrer })}>
                  Login
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
