import { Accordion, Drawer, Grid, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterIcon from '@material-ui/icons/FilterList';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import * as Common from '../../../types/common';
import { AccordionDetailsWithPadding, AccordionSummaryWithPadding } from '../Accordions';
import { ApiTableColumn } from './api-table-config.interface';
import { FilterInputs } from './FilterInputs';

interface TableSettingsDrawerProps {
  orderedConfigColumns: ApiTableColumn<any>[];
  setOrderedConfigColumns: (columns: ApiTableColumn<any>[]) => void;
  searchElement: Common.SearchElement[];
  setSearchElement: (search: any) => void;
  hiddenColumns: string[];
  setHiddenColumns: (hiddenColumns: string[]) => void;
  onClose: () => void;
  defaultFilterOpen?: string;
}
export const TableSettingsDrawer = (props: TableSettingsDrawerProps) => {
  const {
    orderedConfigColumns,
    setOrderedConfigColumns,
    searchElement,
    setSearchElement,
    hiddenColumns,
    setHiddenColumns,
    onClose,
    defaultFilterOpen,
  } = props;

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const newOrderedColumns = [...orderedConfigColumns];
    const [reorderedItem] = newOrderedColumns.splice(result.source.index, 1);
    newOrderedColumns.splice(result.destination.index, 0, reorderedItem);
    setOrderedConfigColumns(newOrderedColumns);
  };

  return (
    <>
      <IconButton onClick={() => setDrawerOpen(true)} style={{ flexGrow: 0 }}>
        <FilterIcon fontSize='small' />
      </IconButton>

      <Drawer
        open={drawerOpen}
        anchor='right'
        variant='temporary'
        ModalProps={{
          keepMounted: true,
        }}
        onClose={() => {
          setDrawerOpen(false);
          onClose && onClose();
        }}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='columns'>
            {(provided) => (
              <Grid container spacing={5} {...provided.droppableProps} ref={provided.innerRef} style={{ width: 400 }}>
                {orderedConfigColumns.map((column, index) => {
                  const visible = !hiddenColumns.includes(column.title);
                  return (
                    <Draggable key={column.title} draggableId={column.title} index={index}>
                      {(provided) => (
                        <Grid
                          item
                          xs={12}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Accordion defaultExpanded={defaultFilterOpen === column.title}>
                            <AccordionSummaryWithPadding expandIcon={<ExpandMoreIcon />} id='filter-expansion'>
                              <Grid container spacing={5}>
                                <Grid item>
                                  <IconButton
                                    size='small'
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setHiddenColumns(
                                        visible
                                          ? [...hiddenColumns, column.title]
                                          : hiddenColumns.filter((f) => f !== column.title),
                                      );
                                    }}
                                  >
                                    {visible ? (
                                      <VisibilityIcon fontSize='small' />
                                    ) : (
                                      <VisibilityOffIcon fontSize='small' />
                                    )}
                                  </IconButton>
                                </Grid>
                                <Grid item>{column.title}</Grid>
                              </Grid>
                            </AccordionSummaryWithPadding>
                            <AccordionDetailsWithPadding>
                              <FilterInputs
                                filterType={column.dataType}
                                value={(searchElement.find((el) => el.fields === column.property) as any) || {}}
                                enums={column.enums}
                                onChange={(value) => {
                                  const index = searchElement.findIndex((f) => f.fields === column.property);
                                  if (index > -1) {
                                    const newSearch: any[] = [...searchElement];
                                    newSearch[index] = { ...value, fields: column.property };
                                    setSearchElement(newSearch);
                                  } else {
                                    setSearchElement([...searchElement, { ...value, fields: column.property }]);
                                  }
                                }}
                              />
                            </AccordionDetailsWithPadding>
                          </Accordion>
                        </Grid>
                      )}
                    </Draggable>
                  );
                })}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Drawer>
    </>
  );
};
