import { GetSerialDto, SerialNumber } from '../types';
import fetch, { RestSpec } from '../utils/fetch';
import { useService } from './useService';

const basePath = window.env.SERIAL_SERVICE_URL || process.env.REACT_APP_SERIAL_SERVICE_URL || '';

export class SerialService {
  private readonly token: string;

  constructor(token: string) {
    this.token = token;
  }

  getSerialNumber(serial: GetSerialDto) {
    return fetch<SerialNumber>({ url: `${basePath}/${serial.serial}` });
  }

  private withAuthHeaders<T>(spec: RestSpec<T>) {
    return {
      ...spec,
      headers: {
        ...spec.headers,
        Authorization: `Bearer ${this.token}`,
      },
    };
  }
}

export function useSerialService() {
  return useService(SerialService);
}
