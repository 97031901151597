import { TableCellProps } from '@material-ui/core';
export type EnumFilterConfig = {
  title: string;
  helperText?: string;
};

export interface ApiTableColumn<T> {
  title: string;
  property: string;
  dataType: tableDataTypeEnum;
  render?: (row: T, index: number) => string | number | React.ReactNode;
  customRenderKey?: string;
  enums?: EnumFilterConfig[];
  disableSort?: boolean;
  align?: TableCellProps['align'];
}

export enum tableDataTypeEnum {
  STRING,
  DATE,
  NUMBER,
  ENUM,
  COMPONENT,
}

export interface ApiTableConfig<T> {
  title: string;
  condition?: any;
  columns: ApiTableColumn<T>[];
  hiddenColumns?: string[];
}
