import { IconButton, StandardTextFieldProps, TextField } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/ClearRounded';
import React from 'react';
interface StringFilterInputProps extends Omit<StandardTextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
}
export const StringFilterInput = (props: StringFilterInputProps) => {
  const { onChange, ...textFieldProps } = props;

  return (
    <TextField
      autoFocus
      fullWidth
      label='Filter'
      {...textFieldProps}
      InputProps={{
        endAdornment: (
          <IconButton size='small' onClick={() => onChange('')}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
