import React from 'react';
//Custom
import loading from '../Util/Assets/loading.svg';

export const Loading = () => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'white',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <img src={loading} alt='Loading' width='20%' height='20%' />
  </div>
);
