import { useRouteMatch } from 'react-router';
import AssembliesPage from '../../Assemblies/Layout/AssembliesPage';
import { Modules } from '../../types';
import { ModulePrivateRoute } from './private-routes.service';
export const usePrivateDashboardModules = (): ModulePrivateRoute[] => {
  const match = useRouteMatch();
  return [
    {
      title: 'Assemblies',
      exact: true,
      path: `${match.url}/assemblies`,
      component: AssembliesPage,
      accessModule: Modules.ASSEMBLY,
    },
  ];
};
