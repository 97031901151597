export const formatDateToBackendDateString = (date?: Date | null | string) => {
  if (typeof date === 'string') return date;
  if (!date || `${date}` === 'Invalid Date') return null;
  const localeDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return localeDate.toISOString().slice(0, 10);
};

export const formatBackendDateStringToDate = (backendDate?: string | null | Date) => {
  if (!backendDate) return null;
  const dateList = (typeof backendDate === 'string' ? backendDate : formatDateToBackendDateString(backendDate))?.split(
    '-',
  );
  return dateList ? new Date(parseInt(dateList[0]), parseInt(dateList[1]) - 1, parseInt(dateList[2])) : null;
};

export const adjustDate = (date: string | Date | null | undefined, days?: number, businessDays = false) => {
  let dayDisplacement = days || -5;
  if (date && `${date}` !== 'Invalid Date') {
    const adjustedDate = formatBackendDateStringToDate(
      typeof date === 'string' ? date : formatDateToBackendDateString(date),
    );
    if (adjustedDate) {
      if (businessDays) {
        for (
          dayDisplacement;
          dayDisplacement <= -5 || dayDisplacement >= 5;
          dayDisplacement += (dayDisplacement / Math.abs(dayDisplacement)) * -5
        ) {
          adjustedDate.setDate(adjustedDate.getDate() + (dayDisplacement / Math.abs(dayDisplacement)) * 7);
        }
        const factor = dayDisplacement / Math.abs(dayDisplacement);
        const adjustedDateDay = adjustedDate.getDay();
        console.log({ day: adjustedDateDay, displacement: dayDisplacement });
        if (factor === -1) {
          adjustedDate.setDate(
            adjustedDate.getDate() + (adjustedDateDay + dayDisplacement <= 0 ? -2 + dayDisplacement : dayDisplacement),
          );
        } else {
          adjustedDate.setDate(
            adjustedDate.getDate() + (adjustedDateDay + dayDisplacement < 6 ? dayDisplacement : dayDisplacement + 2),
          );
        }
      } else {
        adjustedDate.setDate(adjustedDate.getDate() + dayDisplacement);
      }
      return adjustedDate;
    }
    return null;
  }
  return null;
};

export const isDateValid = (date: Date | string | null) =>
  date ? `${typeof date === 'string' ? formatBackendDateStringToDate(date) : date}` !== 'Invalid Date' : false;
